import React from "react";

export default function RsIcons() {
  return (
    <>
      <div
        className="d-flex flex-nowrap justify-content-center"
        target="_blank"
        rel="noopener noreferrer"
      >
        <a
          href="https://www.facebook.com/asisteingsas/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("../images/RsIcons/FB-BLANCO.png")}
            width="50"
            height="50"
            className="p-0 m-0"
            alt="Logo Facebook"
          />
        </a>
        <a
          href="https://www.linkedin.com/company/asisteingsas/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("../images/RsIcons/IN.png")}
            width="50"
            height="50"
            className="p-0 m-0"
            alt="Logo Instagram"
          />
        </a>
        <a
          href="https://www.instagram.com/asisteingsas/?hl=es"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("../images/RsIcons/INSTAGRAM-BLANCO.png")}
            width="50"
            height="50"
            className="p-0 m-0"
            alt="Logo Linkedin"
          />
        </a>
      </div>
    </>
  );
}
