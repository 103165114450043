import React from "react";

export default function Video({ videoSrcURL, videoTitle }) {
  return (
    <div className="video ratio ratio-16x9">
      <iframe
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </div>
  );
}
