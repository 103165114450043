import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";

import "bootstrap/dist/css/bootstrap.min.css";
import NotFound from "./pages/NotFound";
import ReclutaFace from "./pages/ReclutaFace";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ReclutaFace" element={<ReclutaFace />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
