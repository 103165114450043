import React from "react";
import Seo from "../components/Seo";
import Header from "../components/Header";

export default function NotFound() {
  return (
    <>
      <Seo
        title="Asiste Ingeniería S.A.S. | Página no encontrada"
        description=""
        url="https://asisteingenieria.com"
      />
      <Header />
    </>
  );
}
