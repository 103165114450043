import React from "react";
import { Helmet } from "react-helmet";

export default function Seo({ title, description, url }) {
  return (
    <Helmet htmlAttributes={{ lang: `es` }}>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />
    </Helmet>
  );
}
