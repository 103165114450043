import React, { useState } from "react";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import Formulario from "../components/Formulario";
import Header from "../components/Header";
import Seo from "../components/Seo";

export default function ReclutaFace() {
  const [fondo, setFondo] = useState("fff");

  const [form, setForm] = useState("");
  const filterBtn = (button) => {
    setForm(
      button === "1"
        ? "AgCall"
        : button === "2"
        ? "CoordCall"
        : button === "3"
        ? "AnCal"
        : button === "4"
        ? "DataM"
        : button === "5"
        ? "Back"
        : button === "6"
        ? "Form"
        : button === "7"
        ? "AnRec"
        : button === "8"
        ? "DirOp"
        : ""
    );
  };

  return (
    <>
      <Header />
      <Seo
        title="Asiste Ingeniería | Reclutamiento"
        description="¡Únete! Somos una empresa de brazos abiertos, contamos con el mejor y más diverso talento humano que conforma un gran equipo en el servicio de Contact Center."
      />
      <Container fluid>
        <Row className="justify-content-evenly">
          <Col
            lg={7}
            md={6}
            sm={12}
            className="order-md-2"
            style={{ backgroundColor: fondo }}
          >
            <>
              <Container fluid>
                <Row className="justify-content-center">
                  <Col lg={12} md={12} sm={12} className="text-center mt-5">
                    {form === "AgCall" ? (
                      <>
                        <Image
                          fluid
                          src={require("../images/Formulario/BOTONES-37.png")}
                        />
                        <Formulario nombre="Agente Call Center" />
                      </>
                    ) : form === "CoordCall" ? (
                      <>
                        <Image
                          fluid
                          src={require("../images/Formulario/BOTONES-38.png")}
                        />
                        <Formulario nombre="Coordinador Call Center" />
                      </>
                    ) : form === "AnCal" ? (
                      <>
                        <Image
                          fluid
                          src={require("../images/Formulario/BOTONES-39.png")}
                        />
                        <Formulario nombre="Analista de Calidad" />
                      </>
                    ) : form === "DataM" ? (
                      <>
                        <Image
                          fluid
                          src={require("../images/Formulario/BOTONES-40.png")}
                        />
                        <Formulario nombre="Datamarshall" />
                      </>
                    ) : form === "Back" ? (
                      <>
                        <Image
                          fluid
                          src={require("../images/Formulario/BOTONES-41.png")}
                        />
                        <Formulario nombre="Backoffice" />
                      </>
                    ) : form === "Form" ? (
                      <>
                        <Image
                          fluid
                          src={require("../images/Formulario/BOTONES-42.png")}
                        />
                        <Formulario nombre="Formador Call Center" />
                      </>
                    ) : form === "AnRec" ? (
                      <>
                        <Image
                          fluid
                          src={require("../images/Formulario/BOTONES-43.png")}
                        />
                        <Formulario nombre="Analista de Reclutamiento" />
                      </>
                    ) : form === "DirOp" ? (
                      <>
                        <Image
                          fluid
                          src={require("../images/Formulario/BOTONES-44.png")}
                        />
                        <Formulario nombre="Director de Operaciones" />
                      </>
                    ) : form === "" ? (
                      <>
                        <Image
                          fluid
                          src={require("../images/ReclutaFace/INTRO.png")}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </Container>
            </>
          </Col>
          <Col lg={5} md={6} sm={12} className="p-5">
            <Image
              as={Button}
              onClick={() => {
                filterBtn("1");
                setFondo("#febd17");
              }}
              fluid
              src={require("../images/Formulario/BOTONES-37.png")}
            />
            <Image
              as={Button}
              onClick={() => {
                filterBtn("2");
                setFondo("#009bbb");
              }}
              fluid
              src={require("../images/Formulario/BOTONES-38.png")}
            />
            <Image
              as={Button}
              onClick={() => {
                filterBtn("3");
                setFondo("#7ab800");
              }}
              fluid
              src={require("../images/Formulario/BOTONES-39.png")}
            />
            <Image
              as={Button}
              onClick={() => {
                filterBtn("4");
                setFondo("#cf152d");
              }}
              fluid
              src={require("../images/Formulario/BOTONES-40.png")}
            />
            <Image
              as={Button}
              onClick={() => {
                filterBtn("5");
                setFondo("#febd17");
              }}
              fluid
              src={require("../images/Formulario/BOTONES-41.png")}
            />
            <Image
              as={Button}
              onClick={() => {
                filterBtn("6");
                setFondo("#009bbb");
              }}
              fluid
              src={require("../images/Formulario/BOTONES-42.png")}
            />
            <Image
              as={Button}
              onClick={() => {
                filterBtn("7");
                setFondo("#7ab800");
              }}
              fluid
              src={require("../images/Formulario/BOTONES-43.png")}
            />
            <Image
              as={Button}
              onClick={() => {
                filterBtn("8");
                setFondo("#cf152d");
              }}
              fluid
              src={require("../images/Formulario/BOTONES-44.png")}
            />
            <a
              href="https://www.computrabajo.com.co/asisteingenieria"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                fluid
                src={require("../images/Formulario/BOTONES-45.png")}
              />
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
}
