import React from "react";
import { Card, Button, Form } from "react-bootstrap";

export default function Formulario({ nombre }) {
  return (
    <>
      <Card className="text-center shadow-lg m-1 m-md-5 bg-transparent">
        <Card.Body>
          <Form
            method="POST"
            action="/api-php/addRF.php"
            enctype="multipart/form-data"
          >
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label className="text-white fs-5">Nombre</Form.Label>
              <Form.Control
                required
                type="text"
                name="nombre"
                id="nombre"
                placeholder="Ingresa tu nombre y apellido"
                className="opacity-75"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCelular">
              <Form.Label className="text-white fs-5">Celular</Form.Label>
              <Form.Control
                required
                type="tel"
                name="celular"
                id="celular"
                placeholder="Ingresa tu celular"
                className="opacity-75"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCiudad">
              <Form.Label className="text-white fs-5">Ciudad</Form.Label>
              <Form.Select className="opacity-75" name="ciudad" id="ciudad">
                <option value="Bogotá">Bogotá</option>
                <option value="Barranquilla">Barranquilla</option>
              </Form.Select>
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="formBasicHV">
              <Form.Label className="text-white fs-5">Hoja de Vida</Form.Label>
              <Form.Control type="hidden" name="MAX_FILE_SIZE" value="30000" />
              <Form.Control
                required
                type="file"
                name="uploaded_file"
                id="uploaded_file"
                placeholder="Adjunta tu hoja de vida"
                className="opacity-75"
              />
            </Form.Group> */}
            <Form.Group controlId="formBasicCargo">
              <Form.Control
                required
                hidden
                type="text"
                name="cargo"
                id="cargo"
                value={nombre}
              />
            </Form.Group>
            <Button
              type="submit"
              name="cargar"
              size="lg"
              className="opacity-75 bg-transparent border-white"
            >
              Regístrate
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}
